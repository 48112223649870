import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessTokenLocalStorage } from "../../utils/token";
import {axiosClient} from "../../utils/axios";

export const getUser = createAsyncThunk("user/getUser",() => {
    const request = axiosClient.get(`${process.env.REACT_APP_API_URI}api/v1/user`,{headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getAccessTokenLocalStorage()}`
    }}).then((response) => {
            if (response.status === 200){
                return response.data.data;
            }
    });
    return request;
})