import axios from "axios";
import { getRefreshTokenLocalStorage, writeAccessTokenLocalStorage } from "./token";

export const axiosClient = axios.create({
    url: process.env.REACT_APP_API_URI,
    timeout: 5000
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token;
            resolve(axiosClient(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        // Aquí debes hacer la llamada para obtener un nuevo token
        axios
          .post(`${process.env.REACT_APP_API_URI}refresh`,null,{
            headers: {"Authorization": `Bearer ${getRefreshTokenLocalStorage()}`}
          })
          .then((response) => {
            if (response.status === 200) {
              const access_token = response.data.data;
              // Actualiza el token en tu aplicación (por ejemplo, almacenándolo en el estado o en una cookie)
              // ...
              originalRequest.headers.Authorization = 'Bearer ' + access_token;
              writeAccessTokenLocalStorage(access_token);
              onRefreshed(access_token);
              resolve(axiosClient(originalRequest));
            } else {
              // Manejo de errores si la solicitud de renovación de token no es exitosa
              // ...
              reject(error);
            }
          })
          .catch((error) => {
            // Manejo de errores si ocurre un error en la llamada de renovación de token
            // ...
            reject(error);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);
