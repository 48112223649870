import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {axiosClient} from "../../utils/axios";
import { getAccessTokenLocalStorage } from "../../utils/token";

export const getExpenseType = createAsyncThunk("expenseType/get",() => {
    const request = axiosClient.get(`${process.env.REACT_APP_API_URI}api/v1/expense/type`,{headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getAccessTokenLocalStorage()}`
    }}).then((response) => {
        if (response.status === 200){
            return response.data;
        }
        return [];
    });
    return request;
})