import { ExpenseList } from "../../components/expenseList/expenseList";
import { TopBar } from "../../components/topBar/topBar";


export const ExpensesListPage = () => {

    return (
        <>
            <TopBar/>
            <ExpenseList/>
        </>
    );
}