

export const getAccessTokenLocalStorage = () =>{
    return JSON.parse(localStorage.getItem("user")).access_token;
}
export const getRefreshTokenLocalStorage = () =>{
    return JSON.parse(localStorage.getItem("user")).refresh_token;
}

export const writeAccessTokenLocalStorage =(token) => {
    let data = {
        "access_token": token,
        "refresh_token": getRefreshTokenLocalStorage()
    }
    localStorage.setItem("user",JSON.stringify(data));
}