import { useDispatch, useSelector } from "react-redux";
import { TopBar } from "../../components/topBar/topBar";
import { getPartnerData, getPartnerStatus } from "../../features/partner/partnerSlice";
import { getPartnerList } from "../../features/partner/partnerApi";
import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { StyledItemContainer } from "../../components/expenseList/styledItemContainer";
import { DotLoader } from "react-spinners";
import { Navigate, useNavigate } from "react-router";

export const ContactList = () => {
    const dispatch = useDispatch();
    const contactData = useSelector(getPartnerData);
    const contactStatus = useSelector(getPartnerStatus);
    const [contact,setContact] = useState([]);
    const [showSpinner,setShowSpinner] = useState(false);
    const [showError,setShowError] = useState(true);
    const navigate =useNavigate();
    useEffect(() => {
        if (contactStatus === "fulfilled"){
            let content = [];
                for (let index = 0; index < contactData.length; index ++){
                    let element  = contactData[index]
                    content.push(
                        <Box key={contactData[index]} sx={{marginTop: "0.7em", boxShadow: 1}}>
                                <Card  key={element.id}>
                                    <CardContent style={{padding: 0,marginRight: "1em", marginLeft: "1em"}} className="expenseItemCard">
                                        <p style={{padding: "0.5 em", marginBottom: 0}}>{element.name}</p>
                                    </CardContent>
                                </Card>
                            </Box>
                    );
                }
            
            setContact(content);
            setShowSpinner(false);
            
        }
        else if (contactStatus === "idle"){
            setShowSpinner(true);
            dispatch(getPartnerList());
        }
        else if (contactStatus === "rejected" &&  showError){
            setShowError(false);
            toast("Error cargando los gastos");
        }
    },[dispatch,contactData,contactStatus]);

    return (
        <>
            <TopBar/>
            <StyledItemContainer>
                {showSpinner ? <div style={{display: "flex", justifyContent: "center",marginTop: "5em"}}>
                        <DotLoader
                            color="#203448"
                            cssOverride={{}}
                            loading={showSpinner}
                            size={150}
                            speedMultiplier={1}
                    />
                    </div>: <>
                    <Button variant="contained" onClick={() => {navigate("/contact/create")}}>Crear Contacto</Button>
                    {contact}
                    </>            
                }
                
            </StyledItemContainer>
        </>
    );

}