import { createSlice } from "@reduxjs/toolkit";
import { getPaymentMethods } from "./paymentMethodApi";




export const paymentMethodSlice = createSlice({
    name: "paymentMethod",
    initialState: {
        data: [],
        status: "idle",
        error: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getPaymentMethods.fulfilled, (state,action) => {
            state.data = action.payload;
            state.status = "fulfilled";
        }).addCase(getPaymentMethods.pending,(state,action) => {
            state.status = "loading";
        }).addCase(getPaymentMethods.rejected,(state,action) => {
            state.status = "rejected";
        })
    }
});

export const getPaymentMethodsData = (state) => state.paymentMethod.data;
export const getPaymentMethodStatus = (state) => state.paymentMethod.status;