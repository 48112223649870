import styled from "styled-components";
import '../index.css'

export const StyledInput = styled.input`
    height: 30px;
    border-radius: 2px;
    border: 1px solid #c0c0c0;
    background-color: var(--placeholder);
    *,
    :before,
    :after {
    box-sizing: border-box;
    }
`;