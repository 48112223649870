import React, { useCallback, useRef, useEffect, useState } from "react";
import { FaArrowLeft, FaCamera } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import { registerNewExpense } from "../features/expense/expenseSlice";
import { useNavigate } from "react-router";
import { DotLoader } from "react-spinners";

const videoConstraints = {
  facingMode: "environment"
};

export const CameraPage = () => {
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSpinner,setShowSpinner] = useState(true);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(registerNewExpense(imageSrc));
    navigate("/expense/create");
  }, []);

  useEffect(() => {
    const videoElement = webcamRef.current.video;
    if (videoElement) {
      videoElement.style.width = "100%";
      videoElement.style.height = "100%";
      videoElement.style.objectFit = "cover";
      videoElement.parentElement.style.overflow = "hidden";
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 2000);
  }, []);

  return (
    <>
        {showSpinner ?  <div style={{display:"flex", flexDirection: "column",justifyContent:"center",alignItems:"center", height:"100vh"}}>
                                <DotLoader
                                    color="#203448"
                                    cssOverride={{}}
                                    loading={showSpinner}
                                    size={200}
                                    speedMultiplier={1}
                                  />
                                </div> :<></>
                              
          }
           <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                    <Webcam
                                      audio={false}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      videoConstraints={videoConstraints}
                                    />
                                    <div style={{ alignItems: "center",backgroundColor: "transparent", position: "absolute", marginBottom: "4em" }}>
                                      <button onClick={capture} style={{ border: "none", backgroundColor: "transparent", position: "absolute", marginBottom: "3em" }}>
                                        <FaCamera style={{ color: "white",fontSize: "3em"}} />
                                      </button>
                                    </div>
                                    <div style={{backgroundColor: "transparent", position: "absolute", left: "1em ",top: "1em"}}>
                                      <button onClick={() => {navigate("/")}} style={{ border: "none", backgroundColor: "transparent", position: "absolute", marginBottom: "7em"}}>
                                        <FaArrowLeft style={{ color: "black",fontSize: "2em"}} />
                                      </button>
                                    </div>
                                  </div>
    </>
  );
};
