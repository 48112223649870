import { useDispatch, useSelector } from "react-redux";
import { TopBar } from "../../components/topBar/topBar";
import { useEffect, useRef, useState } from "react";
import { getCreateStatus, getNewExpensePhoto, resetCreateStatus, resetExpenseDataStatus } from "../../features/expense/expenseSlice";
import {PartnerSelectComponent} from "../../components/partnerSelectComponent";
import {ExpenseTypeSelectComponent} from "../../components/expenseTypeSelectComponent";
import { StyledButton } from "../../components/styledButton";

import { updateExpense } from "../../features/expense/expenseApi";
import { PaymentMethodSelectComponent} from "../../components/paymentMethodsSelectComponent";
import { StyledInput } from "../../components/styledInput";
import { useNavigate, useParams } from "react-router";
import { DotLoader } from "react-spinners";
import moment from "moment";
import { Box, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from "date-fns";
import { toast } from "react-toastify";

export const ExpenseDetailPage = () => {
    const dispatch = useDispatch();
    const [partner,setPartner] = useState();
    const [expenseType,setExpenseType] = useState();
    const [paymentMethod,setPaymentMethod] = useState();
    const gastoRef = useRef(null);
    const ExpenseCreateStatus = useSelector(getCreateStatus);
    const [showSpinner,setShowSpinner] = useState(false);
    const [price,setPrice] = useState(0.0);
    const navigator = useNavigate();
    const { expenseid } = useParams();
    const expense = useSelector((state) => state.expense.data.filter((element) => element.id == expenseid));
    const [fecha,setFecha] = useState(parseISO(moment(`${expense[0].date.split("/")[2]}-${expense[0].date.split("/")[1]}-${expense[0].date.split("/")[0]}`).format("yyyy-MM-DD")));
    const initialDate = parseISO(moment(`${expense[0].date.split("/")[2]}-${expense[0].date.split("/")[1]}-${expense[0].date.split("/")[0]}`).format("yyyy-MM-DD"));
    useEffect(() => {
        if (ExpenseCreateStatus === "done" && showSpinner){
            navigator("/expenses");
            setShowSpinner(false);
            dispatch(resetExpenseDataStatus());
            dispatch(resetExpenseDataStatus());
        }
    },[ExpenseCreateStatus]);
    useEffect(() => {
        setPrice(Number.parseFloat(expense[0].amount.replace(".","").replace(",",".")));
        setExpenseType({
            label: expense[0].type.name,
            value: expense[0].type.id
        });
        setPartner({
            label: expense[0].partner.name,
            value: expense[0].partner.id
        });
        setPaymentMethod({
            label: expense[0].payment_method.name,
            value: expense[0].payment_method.id
        })
    },[])

    const selectPartnerHandler = (value) => {
        setPartner(value);
    }
    const selectExpenseTypeHandler = (value) => {
        setExpenseType(value);
    }
    const selectPaymentMethod = (value) => {
        setPaymentMethod(value);
    }
    const updateExpenseHandler = () => {
        try{
           
            if (typeof(price) != "number"){
                if (price.includes(",")){
                    toast("El precio no puede contener , especifique el valor decimal con .")
                    return
                }
            }
             
                setShowSpinner(true);
              
                dispatch(updateExpense({
                    id: expenseid,
                    data: {
                        partner: partner,
                        expenseType: expenseType,
                        paymentMethod: paymentMethod,
                        amount: price,
                        description: "",
                        date: fecha
                    }
            }));
            navigator(`/expenses`);}
        
        catch {
        }
            
       
    }
    
    return (
        <>
            <TopBar/>
            
            {showSpinner ? 
                <div style={{display:"flex", flexDirection: "column",justifyContent:"center",alignItems:"center", height:"100vh"}}>
                    <DotLoader
                        color="#203448"
                        cssOverride={{}}
                        loading={showSpinner}
                        size={200}
                        speedMultiplier={1}
                />
                </div>
                : <div style={{marginTop: "60px",display:"flex",flexDirection:"column", maxWidth: "90%"}}>
                  
                    <div style={{zIndex: 1, marginTop: "2em",border: "none",display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",overflow:"hidden",marginBottom: "1em"}}>
                            <img style={{flexShrink: 0,maxWidth: "90%",minHeight: "90%"}}
                                src={`data:image/png;base64, ${expense[0].photo}`} alt="expense photo "/>

                        </div>
                        <Box sx={{display:"flex",flexDirection: "column", width: "95%"}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} localeText={"es_ES"}>
                                <DatePicker inputFormat="yyyy-mm-dd" value={initialDate} style={{marginBottom: "1em"}} onChange={(value) => {
                                    setFecha(value + 1 );
                                    value = new Date(value + 1);
                                    setFecha(`${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`)
                                    }}/>
                            </LocalizationProvider>
                            <br/>
                            <TextField  type="text" onChange={(event) => { 
                                 if (event.target.value !== ""){
                                    setPrice(event.target.value)
                                } 
                                else{
                                    setPrice("");
                                }
                            }} value={price} ref={gastoRef} placeholder="Gasto €" name="amount" style={{marginBottom: "1em"}}></TextField>
                            <PaymentMethodSelectComponent paymentMethodSelected={paymentMethod} onChange={selectPaymentMethod}/>
                            <ExpenseTypeSelectComponent expenseType={expenseType} onChange={selectExpenseTypeHandler}/>
                            <PartnerSelectComponent partner={partner} onChange={selectPartnerHandler} />
                            <Box sx={{display:"flex", justifyContent: "center"}}>
                                <Button variant="contained" onClick={updateExpenseHandler}> Actualizar </Button>

                            </Box>

                        </Box>
                    </div>
                  
                </div>}
        </>
    );
}