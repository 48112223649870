import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCreatedPartner, getPartnerData, getPartnerStatus } from '../features/partner/partnerSlice';
import { getPartnerList } from '../features/partner/partnerApi';


export const PartnerSelectComponent = (props) => {

    const dispatch = useDispatch();
    const partnerStatus = useSelector(getPartnerStatus);
    const partnerData = useSelector(getPartnerData);
    const [partnerSelection,setPartnerSelection] = useState([]);
    const partnerCreated = useSelector(getCreatedPartner);
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
            marginBottom: "1em",
            width: "100%",
            padding: "0.5em"
        })
    }
    useEffect(() => {
        if (partnerStatus === "idle"){
            dispatch(getPartnerList())
        }
        else if (partnerStatus === "fulfilled"){
            let content = [];
            partnerData.forEach(partner => {
                content.push({"value": partner.id,"label": partner.name})
            });
            setPartnerSelection(content);
        }
    },[dispatch,partnerStatus,partnerData]);
    return (
        <Select options={partnerSelection} placeholder={"Selecciona el contacto"} styles={customStyles} value={props.partner} onChange={props.onChange}/>
    );
}