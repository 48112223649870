import styled from "styled-components";


export const StyledLoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    border: none;
    
    label {
        margin-bottom: 0.5em;
    }

    input {
        align-self: center;
    }
`;
