import styled from "styled-components";


export const StyledItemContainer = styled.div`

    border-radis: 1em;
    border-color: red;
    margin-top: 3em;
    width: 100%;
    div.header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.2em;
        margin-bottom: 1em;
    }
    div.header h4 {
        margin 0;
    }
    div.header h5 {
        margin 0;
    }

`;