import { useEffect, useState } from "react";
import {CameraButton} from "../components/cameraButton/cameraButton";
import { ExpenseList } from "../components/expenseList/expenseList";
import { StyledButtonKpi } from "../components/styledButton";
import { StyledKPICard, StyledKPIContent } from "../components/styledKPICard";
import { TopBar } from "../components/topBar/topBar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { AiOutlineCalendar } from 'react-icons/ai';
import { axiosClient } from "../utils/axios";
import { Box, Card, CardContent, Typography } from "@mui/material";
import './home.css';

ChartJS.register(ArcElement, Tooltip, Legend);

export const HomePage = () => {
  const [total,setTotal] = useState(0.0);
  useEffect(() => {
    axiosClient.get(`${process.env.REACT_APP_API_URI}/api/v1/resume`).then((resumeData) => {
      setTotal(resumeData.data.data.total)
    });
  });
    return(
        <>
            <TopBar/>
            <div style={{marginTop: "40px"}}>
              <Box style={{display: "flex",justifyContent: "space-between",marginTop: "60px"}}>
                  <Box style={{marginLeft: "1em"}}>
                    <Typography variant="h6">Gastos totales</Typography>
                    <Typography vartiat="p">Periodo: Anual</Typography>
                  </Box>
                  <Box style={{display: "flex",flexDirection: "colum", alignItems: "center",marginRight: "1em"}}>
                    <Typography variant="h5">{total}</Typography>
                  </Box>
              </Box>

              <Box style={{padding: "1em"}}>
                <ExpenseList nItems={4}/>
              </Box> 
              <div style={{position: "fixed", bottom: "0.5em", right: "1em"}}>
                  <CameraButton/>
              </div>
            </div>
        </>
    );
}