import { useDispatch, useSelector } from "react-redux";
import { TopBar } from "../../components/topBar/topBar";
import { forwardRef, useEffect, useRef, useState } from "react";
import { getCreateStatus, getNewExpensePhoto, resetCreateStatus, resetExpenseDataStatus } from "../../features/expense/expenseSlice";
import {PartnerSelectComponent} from "../../components/partnerSelectComponent";
import {ExpenseTypeSelectComponent} from "../../components/expenseTypeSelectComponent";
import { createExpense } from "../../features/expense/expenseApi";
import { format } from 'date-fns';
import {PaymentMethodSelectComponent} from "../../components/paymentMethodsSelectComponent";
import { useNavigate } from "react-router";
import { DotLoader } from "react-spinners";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Slide, TextField, useMediaQuery } from "@mui/material";
import {createPartner} from '../../features/partner/partnerApi';
import { getUserPaymentMethod } from "../../features/user/userSlice";
import { axiosClient } from "../../utils/axios";
import { getAccessTokenLocalStorage } from "../../utils/token";
export const ExpenseCreatePage = (props) => {
    const dispatch = useDispatch();
    const newExpensePhoto = useSelector(getNewExpensePhoto);
    const [partner,setPartner] = useState();
    const [expenseType,setExpenseType] = useState();
    const userDefaultMethod = useSelector(getUserPaymentMethod)
    const [paymentMethod,setPaymentMethod] = useState();
    const ExpenseCreateStatus = useSelector(getCreateStatus);
    const [showSpinner,setShowSpinner] = useState(false);
    const navigator = useNavigate();
    const currentDate = new Date();
    const [price,setPrice] = useState("0.0");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (userDefaultMethod !== undefined){
           axiosClient.get(`${process.env.REACT_APP_API_URI}api/v1/methods/${userDefaultMethod}`,{headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getAccessTokenLocalStorage()}`
            }}).then((response) => {
                    if (response.status === 200){
                        setPaymentMethod({
                            "value": response.data.data.id,
                            "label": response.data.data.name
                        })
                    }
            });
        }
    },[userDefaultMethod])
    useEffect(() => {
        dispatch(resetCreateStatus());
    },[]);
    useEffect(() => {
        if (ExpenseCreateStatus === "done" && showSpinner){
            navigator("/expenses");
            setShowSpinner(false);
            dispatch(resetCreateStatus());
        }
    },[ExpenseCreateStatus]);
    const selectPartnerHandler = (value) => {
        setPartner(value);
    }
    const selectExpenseTypeHandler = (value) => {
        setExpenseType(value);
    }
    const selectPaymentMethod = (value) => {
        setPaymentMethod(value);
    }
    const submitButtonHandler = () => {
        setShowSpinner(true);
        if (price === "")
            price = "0.0"
        dispatch(createExpense({
            photo: newExpensePhoto,
            partner: partner,
            expenseType: expenseType,
            paymentMethod: paymentMethod,
            amount: Number.parseFloat(price.replace(".","").replace(",",".")),
            date: format(currentDate, 'dd-MM-yyy HH:mm:ss'),
            description: ""
        }));
    }

    return (
        <>
            <TopBar/>
            
            {showSpinner ? 
                <div style={{display:"flex", flexDirection: "column",justifyContent:"center",alignItems:"center", height:"100vh"}}>
                    <DotLoader
                        color="#203448"
                        cssOverride={{}}
                        loading={showSpinner}
                        size={200}
                        speedMultiplier={1}
                />
                </div>
                : <div style={{marginTop: "60px"}}>
                
                <div style={{width: "90%",marginLeft: "1em",marginRight: "1em",marginTop: "3em", alignItems:"center"}}>
                    <img style={{width: "100%"}} src={newExpensePhoto} />
                </div>
                <div style={{marginTop: "0.5em",border: "none",display: "flex", flexDirection: "column", padding: "1.5em", zIndex: "1"}}>
                    <PaymentMethodSelectComponent  paymentMethodSelected={paymentMethod} onChange={selectPaymentMethod}/>
                    <PartnerSelectComponent partner={partner} onChange={selectPartnerHandler} />
                    <ExpenseTypeSelectComponent  expenseType={expenseType} onChange={selectExpenseTypeHandler}/>

                    <div style={{display:"flex", width: "100%", justifyItems:"center",justifyContent: "center"}}>
                        <Button variant="contained" onClick={submitButtonHandler}>Enter</Button>
                    </div>
                </div>
                
            </div>}
        </>
    );
}