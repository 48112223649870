import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import {LoginPage} from './pages/LoginPage';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/home';
import { ExpensesListPage } from './pages/expenses/expenseList';
import {CameraPage} from './pages/camera';
import {ExpenseCreatePage} from './pages/expenses/expenseCreate';
import { GlobalStyle } from './components/globalStyle';
import { ExpenseDetailPage } from './pages/expenses/expenseDetail';
import { ToastContainer } from 'react-toastify';
import { ContactList } from './pages/contact/contactList';
import { ContactCreate, ContactCreatePage } from './pages/contact/contactCreate';
const container = document.getElementById('root');
const root = createRoot(container);
const ProtectedRoute = ({children}) => {
  let user = localStorage.getItem("user");
  if (user === null){
    return <Navigate to={"/login"} replace/>
  }
  else{
  return children;
}
}
const LoggedRoute = ({children}) => {
  let user = localStorage.getItem("user");
  if (user !== null){
    return <Navigate to={"/home"} replace/>
  }
  else{
    return children;
  }
}
document.title = 'Etracker - Urbane';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle/>
        <BrowserRouter>
        <ToastContainer 
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
          <Routes>
            <Route index element={
            <LoggedRoute>
              <LoginPage/>
            </LoggedRoute>
            }/>
            <Route path="/login" element={
              <LoggedRoute>
                <LoginPage/>
              </LoggedRoute>
            }/> 
            <Route path="/home" element={
              <ProtectedRoute children={<HomePage/>}/>
                
            }/>
            <Route path="/expenses" element={
                <ProtectedRoute>
                  <ExpensesListPage/>
                </ProtectedRoute>
              }/>
               <Route path="/contact" element={
                <ProtectedRoute>
                  <ContactList/>
                </ProtectedRoute>
              }/>
               <Route path="/contact/create" element={
                <ProtectedRoute>
                  <ContactCreatePage/>
                </ProtectedRoute>
              }/>
            <Route path="/expense/:expenseid" element={
              <ProtectedRoute>
                <ExpenseDetailPage />
                </ProtectedRoute>} >

            </Route>
            <Route path="/photo" element={<ProtectedRoute><CameraPage /></ProtectedRoute>} />
            <Route path="/expense/create" element={<ProtectedRoute><ExpenseCreatePage/></ProtectedRoute>}/>
          </Routes>
        </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
