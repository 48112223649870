import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getExpenseData,getExpenseStatus } from "../../features/expense/expenseSlice";
import {getExpenseList} from "../../features/expense/expenseApi";
import { StyledItemCard } from "./styledItemCard";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { StyledItemContainer } from "./styledItemContainer";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import './expenseItemCard.css';


export const ExpenseList = (props) => {
    const dispatch = useDispatch();
    const expenseData = useSelector(getExpenseData);
    const expenseStatus = useSelector(getExpenseStatus);
    const [expenses,setExpenses] = useState([]);
    const [showSpinner,setShowSpinner] = useState(false);
    const [showError,setShowError] = useState(true);
    useEffect(() => {
        if (expenseStatus === "fulfilled"){
            let content = [];
            if (props.nItems){
                let index = 0;
                expenseData.forEach(element => {
                   if (index < props.nItems){
                    content.push(
                            <Link to={`/expense/${element.id}`}>
                               <Box key={element.id} sx={{marginTop: "0.7em", boxShadow: 1}}>
                                    <Card  key={element.id}>
                                        <CardContent style={{padding: 0,marginRight: "1em", marginLeft: "1em"}} className="expenseItemCard">
                                            <div>
                                                <p style={{marginTop: "0.5em", marginBottom: 0}}>{element.type.name}</p>
                                                <h6 style={{marginTop: "0.5em", marginBottom: "0.5em"}}>{element.date}</h6>
                                            </div>
                                            <Typography variant="p" >{element.amount}</Typography>

                                        </CardContent>
                                    </Card>
                                </Box>
                            </Link>
                    );
                    index++;

                   }
                });
                
            }
            else{
                for (let index = 0; index < expenseData.length; index ++){
                    let element  = expenseData[index]
                    content.push(
                        <Link to={`/expense/${element.id}`}>
                            <Box key={expenseData[index]} sx={{marginTop: "0.7em", boxShadow: 1}}>
                                <Card  key={element.id}>
                                    <CardContent style={{padding: 0,marginRight: "1em", marginLeft: "1em"}} className="expenseItemCard">
                                        <div>
                                            <p style={{marginTop: "0.5em", marginBottom: 0}}>{element.type.name}</p>
                                            <h6 style={{marginTop: "0.5em", marginBottom: "0.5em"}}>{element.date}</h6>
                                        </div>
                                        <Typography variant="p" >{element.amount}</Typography>

                                    </CardContent>
                                </Card>
                            </Box>
                     </Link>
                    );
                }
            }
            setExpenses(content);
            setShowSpinner(false);
            
        }
        else if (expenseStatus === "idle"){
            setShowSpinner(true);
            dispatch(getExpenseList());
        }
        else if (expenseStatus === "rejected" &&  showError){
            setShowError(false);
            toast("Error cargando los gastos");
        }
    },[dispatch,expenseData,expenseStatus]);

    return (
        <StyledItemContainer>
            <Box className="header">
                <Typography variant="h6">Gastos Recientes</Typography>
                <Link to="/expenses">
                    <Typography variant="p" style={{padding: "0.5em",borderRadius: "0.5em", textDecoration: "none", fontWeight: "bolder"}}>Ver todos</Typography>
                </Link>
            </Box>
            {showSpinner ? <div style={{display: "flex", justifyContent: "center",marginTop: "5em"}}>
                     <DotLoader
                        color="#203448"
                        cssOverride={{}}
                        loading={showSpinner}
                        size={150}
                        speedMultiplier={1}
                />
                </div>: expenses            
            }
            
        </StyledItemContainer>
    );

}