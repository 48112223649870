import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getPaymentMethodsData,getPaymentMethodStatus } from '../features/paymentMethod/paymentMethodSlice';
import {getPaymentMethods} from '../features/paymentMethod/paymentMethodApi';

export const PaymentMethodSelectComponent = (props) => {

    const dispatch = useDispatch();
    const paymentMethodData = useSelector(getPaymentMethodsData);
    const paymentMethodStatus = useSelector(getPaymentMethodStatus);
    const [paymentMethod,setPaymentMethod] = useState([]);
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          padding: "0.5em",
            marginBottom: "1em",
            width: "100%"
        })
    }
    useEffect(() => {
        if (paymentMethodStatus === "idle"){
            dispatch(getPaymentMethods())
        }
        else if (paymentMethodStatus === "fulfilled"){
            let content = [];
            paymentMethodData.forEach(paymentMethod => {                    
                content.push({"value": paymentMethod.id,"label": paymentMethod.name})
            });
            setPaymentMethod(content);
        }
    },[dispatch,paymentMethodStatus,paymentMethodData]);
    return (
        <Select styles={customStyles} options={paymentMethod} placeholder="Modo de pago" value={props.paymentMethodSelected} onChange={props.onChange} />
    );
}