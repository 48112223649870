import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './topBar.css';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { getUserStatus } from "../../features/user/userSlice";
import { getUser } from "../../features/user/userApi";
import { Person, PersonPinCircleOutlined } from "@mui/icons-material";
export const TopBar = () => {
    const userData = useSelector((state) => { return state.user});
    const userStatus = useSelector(getUserStatus);
    const [isOpen, setIsopen] = useState(false);
    const dispatch = useDispatch();
    const [user,setUser] = useState({data: {username: ""}});
    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }
    useEffect(() => {
        if (userStatus === "idle"){
            dispatch(getUser())
        }
        else if (userStatus === "fulfilled"){
            setUser(userData)
        }
    },[userData,userStatus])
    return (
        <div className="topBar">
           <MenuIcon style={{marginLeft: "0.5em", fontSize: "30px", color:`${isOpen == true ? 'black' : 'white'}`}} onClick={ToggleSidebar}/>
           <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
                        <div className="sd-header" style={{marginTop: "1em",display: "flex", flexDirection:"column",alignItems: "center"}}>
                            <img style={{width: "40%", height: "40%"}} src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"/>
                            <h4 className="sd-link">{user.data.username}</h4>
                        </div>
                        <div className="sd-body">
                            <ul>
                                <li><Link to={"/home"} className="sd-link"><DashboardIcon/>  Dashboard</Link></li>
                                <li><Link to={"/expenses"} className="sd-link"><ListIcon/>  Gastos</Link></li>
                                <li><Link to={"/contact"} className="sd-link"><Person/> Contactos</Link></li>

                                <li><Link to={"/photo"} className="sd-link"><AddToPhotosIcon/> Agregar Gasto</Link></li>
                            </ul>
                        </div>
            </div>
        </div>
    );
}