import { Button } from "@mui/material";
import styled from "styled-components";

export const  StyledButton = (props) => {
  const style={
    backgroundColor: "--button-create",
    ...props.style,
  };
  return <Button style={style} variant={props.variant} onClick={props.onClick}>
    {props.content}
  </Button>
}
/*styled.button`
display: inline-block;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  background-color: #2196F3;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: var(--button-create);
`;*/

export const StyledButtonKpi = styled.button`
    display: flex;
    margin-right: 0.3em;
    padding: 0.6em;
    background-color: rgb(1, 113, 223);
    border-color: rgb(1, 113, 223);
    border-size: 0.3em;
    border-radius: 1em;
    p {
        color: white;
        margin: 0;
    }
    svg{
        color : white;
        margin-right: 1em;
    }
    &:hover {
        opacity: 0.8;
      }
      
      /* Estilos al hacer clic (active) */
      &:active {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
`;