import { createGlobalStyle } from 'styled-components';
import '../index.css';
import '../fonts/Arial.ttf';

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Arial', sans-serif;
        background-color: var(--background-color);
        width: fit-content;
        min-width: 100%;
        min-height: 100vh;
        height: 100%;
    }

    * {
        font-family: 'Arial', sans-serif;

    }
    a {
        color: black;
        font-family: Arial;
        text-decoration: none;
    }
`;