import styled from "styled-components";


export const RoundButtonStyled = styled.button`
    background-color: var(--topbar-color);
    border-radius: 50%;
    padding: 0.5em;
    position: absolute;
    right: 0.3em;
    bottom: 1em;
    border: none;
    svg{
        display: block;
        color:white;
        padding: 1em;
        transform: scale(1.5)
    }
`;