import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {axiosClient} from "../../utils/axios";
import { getAccessTokenLocalStorage } from "../../utils/token";


export const getPartnerList = createAsyncThunk("partner/getPartnerList", () => {
    const request = axiosClient.get(`${process.env.REACT_APP_API_URI}api/v1/partner`,{headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getAccessTokenLocalStorage()}`
    }}).then((response) => {
        if (response.status === 200){
            return response.data.data;
        }
        return [];
    });
    return request;
})

export const createPartner = createAsyncThunk("partner/createPartner", (partnerData) => {
    const request = axiosClient.post(`${process.env.REACT_APP_API_URI}api/v1/partner`,partnerData,{headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getAccessTokenLocalStorage()}`
    }}).then((response) => {
        if (response.status === 201){
            return response.data.data;
        }
        return [];
    });
    return request;
})