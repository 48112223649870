import { createSlice } from "@reduxjs/toolkit";
import {getExpenseType} from "./expenseTypeApi";
import { getExpenseList } from "../expense/expenseApi";

export const expenseTypeSlice = createSlice({
    name:"expenseType",
    initialState:{
        status: "idle",
        data: [],
        error: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getExpenseType.fulfilled,(state,action) => {
            state.status = "fulfilled";
            state.data = action.payload.data;
        }).addCase(getExpenseList.rejected,(state,action) => {
            state.status = "rejected";
            state.error = action.payload
        }).addCase(getExpenseList,(state,action) => {
            state.status="loading"
        })
    }
})

export const getExpenseTypeData = (state) => state.expenseType.data;
export const getExpenseTypeStatus = (state) => state.expenseType.status;