import { useState } from "react";
import { DotLoader } from "react-spinners";
import { TopBar } from "../../components/topBar/topBar";
import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { createPartner } from "../../features/partner/partnerApi";
import { useNavigate } from "react-router";


export const ContactCreatePage = () => {
    const dispatch = useDispatch();
    const [showSpinner,setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const submitButtonHandler = () => {
        setShowSpinner(true);
        dispatch(createPartner({
            name: contactName,
            mail: contactEmail,
            mobile: contactMovil
        }));
        navigate("/contact")
    };
    let contactName ="";
    let contactEmail ="";
    let contactMovil = "";


    return (
        <>
            <TopBar/>
            
            {showSpinner ? 
                <div style={{display:"flex", flexDirection: "column",justifyContent:"center",alignItems:"center", height:"100vh"}}>
                    <DotLoader
                        color="#203448"
                        cssOverride={{}}
                        loading={showSpinner}
                        size={200}
                        speedMultiplier={1}
                />
                </div>
                : <div style={{marginTop: "60px"}}>
                
                <div style={{marginTop: "0.5em",border: "none",display: "flex", flexDirection: "column", padding: "1.5em", zIndex: "1"}}>
                    <div style={{marginTop: "0.5em",border: "none",display: "flex", flexDirection: "column", padding: "1.5em", zIndex: "1"}}>
                        <TextField id="outlined-basic" margin="dense" fullWidth label="Nombre del contacto" variant="outlined" sx={{marginBottom: "2em"}} onChange={(event) => contactName = event.target.value}/>
                        <TextField id="outlined-basic" margin="dense" fullWidth label="Móvil" variant="outlined" sx={{marginBottom: "2em"}} onChange={(event) => contactMovil = event.target.value}/>
                        <TextField id="outlined-basic" margin="dense"  fullWidth label="Email" variant="outlined" sx={{marginBottom: "2em"}} onChange={(event) => contactEmail = event.target.value}/>
                    </div>
                    <div style={{display:"flex", width: "100%", justifyItems:"center",justifyContent: "center"}}>
                        <Button variant="contained" onClick={submitButtonHandler}>Enter</Button>
                    </div>
                </div>
                
            </div>}
        </>
    );
}