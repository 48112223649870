import styled from "styled-components";
import '../../index.css';


export const StyledItemCard = styled.div`   
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--cardExpenseBackground);
    padding: 1em;
    margin-bottom: 0.3em;
    .expenseGeneral {
        display: flex;
        align-items: center;
    }
    .expenseGeneral svg {
        font-size: 40px;
        margin-right: 0.5em;
    }

    h2 {
        margin: 0;
        font-size: 20px;
    }
    h4{
        margin: 0;
    }
`;
