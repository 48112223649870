import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getExpenseType} from '../features/expenseType/expenseTypeApi';
import {getExpenseTypeData,getExpenseTypeStatus} from "../features/expenseType/expenseTypeSlice";
import Select from 'react-select'

export const ExpenseTypeSelectComponent = (props) => {

    const dispatch = useDispatch();
    const expenseTypeData = useSelector(getExpenseTypeData);
    const expenseTypeStatus = useSelector(getExpenseTypeStatus);
    const [ExpenseTypeSelection,setExpenseTypeSelection] = useState([]);
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
            marginBottom: "1em",
            padding: "0.5em",
            width: "100%"
        })
    }
    useEffect(() => {
        if (expenseTypeStatus === "idle"){
            dispatch(getExpenseType())
        }
        else if (expenseTypeStatus === "fulfilled"){
            let content = []
            for (let record in expenseTypeData) {
                content.push({
                    "value": expenseTypeData[record].id,
                    "label": expenseTypeData[record].name
                })
            }
            setExpenseTypeSelection(content);
        }
    },[
        dispatch,expenseTypeData,expenseTypeStatus
    ])
    return (
        <Select styles={customStyles} placeholder="Tipo de Gasto" options={ExpenseTypeSelection} value={props.expenseType} onChange={props.onChange}/>
    );
}