import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {getAccessTokenLocalStorage} from "../../utils/token";
import {axiosClient} from "../../utils/axios";

export const getExpenseList = createAsyncThunk("expense/getExpenseList", () => {
    const request = axiosClient.get(
        `${process.env.REACT_APP_API_URI}api/v1/expense`,{
        headers: {"Authorization": `Bearer ${getAccessTokenLocalStorage()}`}
    }).then((response) => {
        if (response.status === 200){
            return response.data;
        }
        return [];
    });
    return request;
});

export const createExpense = createAsyncThunk("expense/createExpense", (expenseData) => {
    const request = axiosClient.post(`${process.env.REACT_APP_API_URI}api/v1/expense`,expenseData,{
        headers: {"Content-Type": "application/json","Authorization": `Bearer ${getAccessTokenLocalStorage()}`}
    }).then((response) => {
        if (response.status === 201){
            return response.data
        }
        return {};
    }).catch((error) => {});
    return request
});

export const updateExpense = createAsyncThunk("expense/updateExpense", (expenseUpdateData) => {
    const request = axiosClient.put(`${process.env.REACT_APP_API_URI}api/v1/expense/${expenseUpdateData.id}`,expenseUpdateData.data,{
        headers: {"Content-Type": "application/json","Authorization": `Bearer ${getAccessTokenLocalStorage()}`}
    }).then((response) => {
        if (response.status === 201){
            return response.data
        }
        return {};
    });
    return request
})