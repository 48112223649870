import React, { useEffect, useRef } from 'react';
import { StyledInput } from '../components/styledInput';
import { StyledButton } from '../components/styledButton';
import { ToastContainer, toast } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, getUserError, getUserStatus, setUser } from '../features/user/userSlice';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { StyledLoginForm } from '../components/styledLoginForm';
import { Button, Input, TextField } from '@mui/material';
injectStyle();

export function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const onClicklHandler = (event) => {
    event.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URI}login`,{
      "username":formRef.current.username.value,
      "password":formRef.current.password.value
    }).then((response) =>{
      if (response.status === 200){
        dispatch(setUser(response.data.user));
        localStorage.setItem("user",JSON.stringify(response.data.data));
        navigate("/home");
      }

    }).catch((codeErrorRequest) => {
      if (codeErrorRequest.status !== 200){
        if (codeErrorRequest.code === "ERR_NETWORK")
        toast("Fallo de conexión.")
        else if (codeErrorRequest.response.status === 404){
          toast("Error en el inicio de sesión");
        }
      }
    }); 
  }
  return (
  <>
  <div style={{height: "100vh",width: "100vw", display:"flex",flexDirection:"column",alignItems:"center"}}>
      <div style={{flex: 1,display: "flex", justifyContent: "center", marginTop: "60px"}}>
        <img  style={{width: 'auto', height: "50%"}} src='https://urbane.hromerol.es/web/image?model=res.company&id=1&field=logo&unique=21062023093429'/>
      </div>
      <StyledLoginForm ref={formRef} style={{flex:2}}>
        <TextField variant="filled"  label="Usuario" name="username" style={{"marginBottom": "1em","width": "100%"}}/>
        <TextField variant="filled"  label="Contraseña" name="password" type="password" style={{"marginBottom": "1em","width": "100%"}}/>
      </StyledLoginForm>
      <StyledButton style={{marginBottom: "3em"}} variant="contained" onClick={onClicklHandler} content={"Enter"}/>
  </div>
  </>
  
  );
}

