import { createSlice } from "@reduxjs/toolkit";
import { createPartner, getPartnerList } from "./partnerApi";
import { act } from "react-dom/test-utils";


export const PartnerSlice = createSlice({
    name: "partner",
    initialState: {
        status: "idle",
        data: [],
        error: null,
        createdPartner: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPartnerList.fulfilled,(state,action) => {
            state.status = "fulfilled"
            state.data = action.payload
        }).addCase(getPartnerList.rejected,(state,action) => {
            state.status = "rejected"
        }).addCase(getPartnerList.pending,(state,action) => {
            state.status="loading"
        }).addCase(createPartner.fulfilled,(state,action) => {
            state.data.push(action.payload);
            state.createdPartner = action.payload;
        }).addCase(createPartner.rejected,(state,action) => {
            state.status="rejected";
            state.error = action.error;
        })
    }
})

export const getPartnerData = (state) => state.partner.data;
export const getPartnerStatus = (state) => state.partner.status;
export const getCreatedPartner= (state) => state.partner.createdPartner;