import styled from "styled-components";


export const StyledKPICard =  styled.div`
  background-color: var(--cardBackgound);
  color: rgb(136,136,136);
  border-radius: 0.6em;
  margin-left: 1em;
  margin-top: 1em;
  div.p {
    margin: 0;
  }
  `;

export const StyledKPIContent = styled.div`
padding: 0.7em;
font-family: 'Arial';
  h4 {
    margin: 0;
    color: rgb(243,243,243);
  }
  h3 {
    margin: 0;
    color: rgb(243,243,243);
  }
  h2 {
    margin-bottom: 0.5em;
    font-size: 30px;
    color: rgb(243,243,243);
  }
`;