import { act } from "react-dom/test-utils";
import { createExpense, getExpenseList, updateExpense } from "./expenseApi";

const { createSlice } = require("@reduxjs/toolkit");


export const ExpenseSlice = createSlice({
    name: "expense",
    initialState:{
        data: [],
        status: "idle",
        error: null,
        newExpense: {
            name: "",
            type:"",
            description: "",
            amount:"",
            photo:"",
            partner: ""
        },
        createStatus: "idle"
    },
    reducers:{
        registerNewExpense(state,action){
            state.newExpense = {...state.newExpense, photo: action.payload}
        },
        resetCreateStatus(state,action) {
            state.createStatus = "idle";
        },
        resetExpenseDataStatus(state,action) {
            state.status = "idle";
        },
        addExpense(state,action) {
            state.data =[action.payload,...state.data]
        },
        setExpenseList(state,action){
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getExpenseList.fulfilled,(state,action) =>{
            state.status = "fulfilled";
            state.data = action.payload.data; 
        }).addCase(getExpenseList.rejected,(state,action) => {
            state.status = "rejected";
            state.error = action.error;
        }).addCase(getExpenseList.pending, (state,action) => {
            state.status = "loading";
        }).addCase(createExpense.fulfilled,(state,action) => {
            state.status = "idle";
            state.createStatus = "done";
        }).addCase(updateExpense.fulfilled,(state, action) => {
            state.status = "idle"; // To update all 
        })
    }
});

export const getExpenseData = (state) => state.expense.data;
export const getExpenseStatus = (state) => state.expense.status;
export const {registerNewExpense,resetCreateStatus,resetExpenseDataStatus,addExpense} = ExpenseSlice.actions;
export const getCreateStatus = (state) => state.expense.createStatus;
export const getNewExpensePhoto = (state) => state.expense.newExpense.photo;