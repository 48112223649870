import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "./userApi";


export const UserSlice = createSlice({
    name: "user",
    initialState: {
        data: {},
        status: "idle",
        error: null
    },
    reducers: {
        setUser: (state,action) => {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state,action) => {
            state.data = action.payload;
            state.status = "fulfilled"
        }).addCase(getUser.rejected, (state,action) => {
            state.error = action.payload;
            state.status = "rejected"
        })
    }
});

export const getUserData = (state) => state.user.data;
export const getToken = (state) => state.user.token;
export const getUserStatus = (state) => state.user.status;
export const getUserError = (state) => state.user.error;
export const {setUser} = UserSlice.actions;
export const getUserPaymentMethod = (state) => state.user.data.default_payment_method;