import { configureStore } from '@reduxjs/toolkit';
import { UserSlice } from '../features/user/userSlice';
import { ExpenseSlice } from '../features/expense/expenseSlice';
import { expenseTypeSlice } from '../features/expenseType/expenseTypeSlice';
import { PartnerSlice } from '../features/partner/partnerSlice';
import { paymentMethodSlice } from '../features/paymentMethod/paymentMethodSlice';

export const store = configureStore({
  reducer: {
    user: UserSlice.reducer,
    expense: ExpenseSlice.reducer,
    expenseType: expenseTypeSlice.reducer,
    partner: PartnerSlice.reducer,
    paymentMethod: paymentMethodSlice.reducer
  },
});
