import { IoMdCamera } from "react-icons/io";
import { RoundButtonStyled } from "./cameraButtonStyled";
import { Link } from "react-router-dom";


export const CameraButton = ()  => {


    return (
        <Link to="/photo">{<RoundButtonStyled>
            <IoMdCamera/>
            </RoundButtonStyled>}
        </Link>
    );
}